import React, { useState, useEffect } from 'react';
import useSound from 'use-sound';

import thePlayers from './audio/players.mp3'

function PlayerForm ({ addPlayer, setGameState, players }) {
    const [value, setValue] = useState('');

    const handleSubmit = e => {
      e.preventDefault();
      if (!value) return;
      addPlayer(value);
      setValue('');
    };

    const handleDoneAddingPlayers = f => { f.preventDefault();setGameState('AddInitials');}
	
	
	const randomNameSounder = () => {
		var sounders = [
		  "pirate-coat",
		  "liber",
		  "legionnaire",
		  "sauce",
		  "paul-edwardo-lambert",
		  "dwight-hawkey",
		  "buffalo-sabers",
		  "nobodyaskedyoukevin",
		  "pompapaul",
		  "pompapaul2",
		  "sauce-laugh",
		  "how-long-are-you-gonna-talk",
		  "duh",
		  "nobody-cares",
		  "we-dont-even-care" 
		];
		var randomPlayer = sounders[Math.floor(Math.random() * sounders.length)];
		thePlayersAudio({id : randomPlayer});
	  };

	  const [thePlayersAudio] = useSound(thePlayers, {
		sprite: {
			"pirate-coat": [
				0,
				1780.249433106576
			  ],
			  "liber": [
				3000,
				634.6031746031748
			  ],
			  "legionnaire": [
				5000,
				1094.2403628117913
			  ],
			  "sauce": [
				8000,
				767.8004535147398
			  ],
			  "paul-edwardo-lambert": [
				10000,
				2919.8185941043084
			  ],
			  "the-cartoon-afficianado": [
				14000,
				2823.356009070295
			  ],
			  "dwight-hawkey": [
				18000,
				1341.1791383219943
			  ],
			  "buffalo-sabers": [
				21000,
				1635.7823129251692
			  ],
			  "nobodyaskedyoukevin": [
				24000,
				1542.2902494331083
			  ],
			  "its-time-for-initials": [
				27000,
				1725.3061224489804
			  ],
			  "pompapaul": [
				30000,
				750.2721088435358
			  ],
			  "pompapaul2": [
				32000,
				866.6893424036317
			  ],
			  "sauce-laugh": [
				34000,
				1473.151927437641
			  ],
			  "how-long-are-you-gonna-talk": [
				37000,
				1996.9160997732445
			  ],
			  "duh": [
				40000,
				510.8390022675735
			  ],
			  "nobody-cares": [
				42000,
				1567.3469387755076
			  ],
			  "we-dont-even-care": [
				45000,
				2113.0158730158755
			  ]
		}
	  });

	useEffect(() => {
		randomNameSounder();
	}, [players]);
	
	useEffect(() => {
		//console.log('playerform rendered');
		}, []);

    function shuffle(a) {
      for (let i = a.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
	}
	
	

    let placeholderNames = ['AJ', 'Meatsauce', 'Cartoon Aficionado', 'Greasy', 'Nacho Libre', 'Perry', 'Rosey', 'Marney', 'Gale', 'Carly', 'Lawyer Lambert', 'Zach Daniels', 'Rube', 'Tommy', 'Hawkey'];
    placeholderNames = 'Enter player names like ' + shuffle(placeholderNames).slice(0,2).join(', ') + ', etc. here.';
	//console.log('ran player form');
    return (
      <form>
        <input
          type='text'
          className='input'
          value={value}
          placeholder={placeholderNames}
          onChange={e => setValue(e.target.value)} />
        <button className='btn-large waves-effect waves-light  blue darken-4' onClick={handleSubmit}>
          Add Player<i className='material-icons right'>person_add</i>
        </button>
        {players.length > 0 &&
         <button className='btn-large waves-effect waves-light green darken-4' onClick={handleDoneAddingPlayers}>
           Done Adding Players
         </button>}
      </form>
    );
  };
  export default PlayerForm;
