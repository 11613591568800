import React, { useState } from 'react';
import FadeIn from 'react-fade-in';


function InitialsForm ({ addInitials }) {
	const [value, setValue] = useState('');
  
	const handleSubmit = e => {
	  e.preventDefault();
	  if (!value) return;
	  addInitials(value);
	  setValue('');
	};
	//console.log('ran intials form');
	return (
		<div className='container'>
		<FadeIn>
		 <div className='initials'>
		   <h3>Enter the initials you have selected for this game</h3><form onSubmit={handleSubmit}>
		<input
		  type='text'
		  className='input'
		  value={value}
		  placeholder='Enter both initials from the card bundle you&apos;ve selected here.'
		  onChange={e => setValue(e.target.value.toUpperCase())}
		  />
		<button className='btn-large waves-effect waves-light green darken-4'>
		  Add Initials
		</button>
	  </form>
	  <h5>(Now is the time to make sure you unmute your phone if you would like sound effects for right and wrong answers etc.)</h5>
             </div>
           </FadeIn>
           </div>
	);
  };

export default InitialsForm;