import React, { useState, useEffect} from 'react';
import FadeIn from 'react-fade-in';
import { AnimateOnChange } from 'react-animation';
import logo from './images/initials-logo-black.png';
import useSound from 'use-sound';
//import useUndo from 'use-undo';
//import itsTimeToPlayInitialsAudio from './audio.js'
import InitialsForm from "./initialsform.js";
import PlayerForm from "./playerform.js";


import './App.css';

import clues from './audio/clues.mp3';
import thatsRight from './audio/thats-right.mp3';
import wrong from './audio/wrong.mp3';

import thePlayers from './audio/players.mp3'
import youBlewIt from './audio/you-blew-it.mp3';
import itsTimeToPlayInitials from './audio/time-to-play.mp3';



function Player ({ player, index, incorrectAnswer, removePlayer, correctAnswer, gameState, item, clue}) {
  return (
    <div className='player card' key={player.id}>
      <div className='card-content' key={player.id}>
        <span className='card-title'>{player.playerName}</span>
        <h2 className='right valign-top'><span className=''><AnimateOnChange
              animationIn="bounceIn"
              animationOut="bounceOut"
              durationOut={500}
            >{player.guessedCorrect.length}</AnimateOnChange></span></h2>
        <div>


          {((player.winner===true) && (parseInt(item, 10) < 13)) &&
            <h1>WINNER!! But, play the game out for pride.</h1>
          }
          
          {((player.winner===true) && (parseInt(item, 10) === 13)) &&
            <h1>WINNER!!</h1>
          }

          {((player.winner===true) && (parseInt(item, 10) > 13)) &&
            <h1>WINNER!!</h1>
          }

          {((parseInt(player.rank, 10)===1 && (13 - parseInt(item, 10) <= 1)) && player.winner===false) &&
            <NextScoreWins playerName={player.playerName} />
          }

          <CurrentRank rank={player.rank} isTied={player.isTied} />

          {player.inARowCorrect>1 &&
            <p>
              &#128293; Is on a hot streak with {player.inARowCorrect} correct in a row!
            </p>
          }

          <MathematicallyEliminated isMathematicallyEliminated={player.isMathematicallyEliminated}/>
          {((parseInt(player.backFromLeader, 10)===(13 - parseInt(item, 10))) && player.rank>1) &&
            <RunTheTableToTie backFromLeader={player.backFromLeader} />
          }
          {((parseInt(player.backFromLeader, 10)===(12 - parseInt(item, 10))) && !player.winner) &&
            <p>
              Could run the table to win.
            </p>
          }
          {player.backFromLeader>0 &&
            <p>
              Is {player.backFromLeader} back from the leader.{(parseInt(player.backFromLeader, 10) > 4) && '. Could this be Parrish with us today?'}
            </p>
          }
          {player.inARowWrong>1 &&
            <p>
              &#128169; Wrong streak in play!! {player.playerName} has answered {player.inARowWrong} wrong items in a row!
            </p>
          }
          <RunningCommentary player={player.playerName} />
          {player.guessedWrong.length > 0 && player.guessedWrong.length < 4 &&
           <p>
             Has guessed {player.guessedWrong.length} wrong.
           </p>
           }
          {player.guessedWrong.length > 0 && player.guessedWrong.length >= 4 &&
           <p>
             &#128169; Is now up to {player.guessedWrong.length} wrong guesses. Maaaybe think before you speak Meatsauce.
           </p>
           }
          {((gameState == 'PlayGame' && clue > 0 && item <= 12) || (gameState == 'PlayGame' && clue > 0 && item > 12 && !player.isMathematicallyEliminated)) && 
              <FadeIn>
                <div className="card-action">
                <button 
                className="btn-large waves-effect waves-light materialize-red" 
                onClick={() => incorrectAnswer(index, item, clue)}
                disabled={player.guessedItemWrong}>
                Wrong!!!
                </button>
                <button 
                  className="btn-large waves-effect waves-light green" 
                  onClick={() => correctAnswer(index)}
                  disabled={player.guessedItemWrong}>
                  That's Right!</button>
              </div>
              </FadeIn>}
           {gameState == 'AddPlayers' &&
            <div className="card-action">
              <button 
                className="btn waves-effect waves-light materialize-red" 
                onClick={() => removePlayer(index)}>
                  Delete Player<i className="material-icons right">delete_forever</i>
              </button>
            </div>}
        </div>
      </div>
    </div>
  );
};

function RunningCommentary (player) {
  return (
    answeredCorrectly(player)
  );
};

function answeredCorrectly (player) {
  return (
    <FadeIn>
      {player.guessedCorrect != null &&
       <h3>Has answered {player.guessedCorrect.length} correctly.</h3>}
    </FadeIn>
  );
};

function MathematicallyEliminated (player) {
  return (
    <FadeIn>
      {player.isMathematicallyEliminated===true &&
       <p>Has been mathematically eliminated.</p>
       }
    </FadeIn>
  );
};

function NextScoreWins (player) {
  return (
    <FadeIn>
       <p>Is going for the win! The next correct answer from {player.playerName} will win the game.</p>
    </FadeIn>
  );
};

function RunTheTableToTie(player) {
  let RunTheTableToTieJokes = [
    "Needs to run the table to tie, but unless this is AJ, no way they are coming back from ",
    "Needs to run the table to tie... but yeaahh, not happening when they are ",
    "Will be eliminated on the next point they miss. Must run the table. "
  ];
  RunTheTableToTieJokes = RunTheTableToTieJokes[Math.floor(Math.random() * RunTheTableToTieJokes.length)];
  let runTheTableToTiemsg = '';
  if (parseInt(player.backFromLeader , 10) > 2) {
    runTheTableToTiemsg = RunTheTableToTieJokes + player.backFromLeader + ' down.';
  } else {
    runTheTableToTiemsg = 'Needs the next one to tie.';
  }
  return (
      <p key={player.id}>
        {runTheTableToTiemsg}
      </p>
  );
};

function CurrentRank (props) {
  let msg = null;
  if (props.rank === 1 && props.isTied===true) {
    msg = 'Tied for the lead!!';
  } else if (props.rank === 1) {
    msg = 'All alone in the lead!!';
  } 
  return (
      <p>{msg}</p>
  );
};



function App () {
  const [players, setPlayers] = useState([]);
  const [initials, setInitials] = useState([]);
  const [item, setItem] = useState([1]);
  const [clue, setClue] = useState([0]);
  const [gameState, setGameState] = useState('Loading');
  const [undo, setUndo] = useState([]);
  const [lastClick, setLastClick] = useState([]);

  /*  
  useEffect(() => {
    // logs 'useEffect inside handlers
    // logs the active ticker currently shown (by querying the DOM)
    return () => {
      // logs 'useEffect cleanup handler'
      };
    });
    */

  const [cluesAudio] = useSound(clues, {
    sprite: {
      "clue-1": [
        0,
        5276.734693877551
      ],
      "clue-2": [
        7000,
        5198.367346938776
      ],
      "clue-3": [
        14000,
        5276.73469387755
      ],
      "clue-4": [
        21000,
        5355.102040816327
      ],
      "clue-5": [
        28000,
        5328.9795918367345
      ],
      "clue-6": [
        35000,
        5328.9795918367345
      ]
    }
  });

  
  const [thatsRightAudio] = useSound(thatsRight);
  const [wrongAudio] = useSound(wrong);
  const [youBlewItAudio] = useSound(youBlewIt);
  const [itsTimeToPlayInitialsAudio] = useSound(itsTimeToPlayInitials);

 


 /*
  const [
    countState,
    {
      set: setCount,
      reset: resetCloud,
      undo: undoCount,
      redo: redoCount,
      canUndo,
      canRedo
    }
  ] = useUndo(0);
*/
  const addPlayer = playerName => {
    const newPlayers = [...players, { playerName, guessedCorrect: [], guessedWrong: [], rank:0, rankMessage:'', isMathematicallyEliminated:false, isInTheLead:false, isTied:false, winner:false, backFromLeader:0, inARowCorrect:0, inARowWrong:0}];
    setUndo([...players]);
    setPlayers(newPlayers);
    //console.log(playerName + ' has been added to the game.');
    //randomNameSounder();
  };

  const incorrectAnswer = index => {
    wrongAudio();
    const newPlayers = [...players];
    if (newPlayers[index].guessedWrong == null) {
      newPlayers[index].guessedWrong = [];
    };
    newPlayers[index].guessedItemWrong = true;
    newPlayers[index].guessedWrong.push([item,clue]);
    setUndo([...players]);
    setPlayers(newPlayers);
    setLastClick(['incorrectAnswer',index]);
    //console.log('Incorrect answer given by ' + newPlayers[index].playerName + ' on clue ' + clue + ' of item ' + item + ' of ' + initials);
  };

  const resetGuesses = index => {
    const newPlayers = [...players]
    newPlayers.forEach(element => {
      element.guessedItemWrong = false
    })
    setPlayers(newPlayers)
  }

  const correctAnswer = index => {
    thatsRightAudio();
    const newPlayers = [...players]
    if (newPlayers[index].guessedCorrect == null) {
      newPlayers[index].guessedCorrect = [];
    }
    newPlayers[index].guessedCorrect.push([item, clue]);
    setUndo([...players]);
    setPlayers(newPlayers);
    setLastClick(['correctAnswer',index])
    //console.log('Correct answer given by ' + newPlayers[index].playerName + ' on clue ' + clue + ' of item ' + item + ' of ' + initials);
    rankPlayers();
    nextItem();
    //useEffect(() => {window.scrollTo(0,0)},[]);
  }



  const rankPlayers = () => {
    const newPlayers = [...players]
    var array = [];

    for (var key in newPlayers) {
      array.push(newPlayers[key]); 
    }

    //Rank the players
    array.sort(function(a, b){
        return b.guessedCorrect.length - a.guessedCorrect.length;
    });
    var rank = 1;
    for (var i = 0; i < array.length; i++) {
      if (i > 0 && array[i].guessedCorrect.length < array[i - 1].guessedCorrect.length) {
        rank++;
      } 
      array[i].rank = rank;
    }

    //Check if players are tied
    for (var j = 0; j < array.length; j++) {
      if ((j > 0 && array[j].guessedCorrect.length == array[j - 1].guessedCorrect.length) || (j < (array.length-1) && array[j].guessedCorrect.length == array[j + 1].guessedCorrect.length)) {
        array[j].isTied = true;
      }  else {
        array[j].isTied = false;
      }
    }

    //Check if players are mathmatically eliminated
    let itemsLeft = 12 - item;
    let leaderScore =  Math.max.apply(Math, array.map(function(o) { return o.guessedCorrect.length; }))
    for (var k = 0; k < array.length; k++) {
      if (k > 0 && itemsLeft < (leaderScore - array[k].guessedCorrect.length)) {
        array[k].isMathematicallyEliminated = true;
        array[k].backFromLeader = leaderScore - array[k].guessedCorrect.length;
        //console.log(array[k].playerName + ' has been eliminated.');
      }  else {
        array[k].isMathematicallyEliminated = false;
        array[k].backFromLeader = leaderScore - array[k].guessedCorrect.length;
      }
    }

    //check if player wins
    var eliminated = 0;
    for (var l = 0; l < array.length; l++) {
      if (array[l].isMathematicallyEliminated===true) {
        eliminated++;
      }
    };

    if (array.length-1 === eliminated) {
      array[0].winner=true;
      //console.log(array[0].playerName + ' wins');
    };

    //check how many in a row correct the player has
    for (var m = 0; m < array.length; m++) {
      var correctInARow = 0;
      if (array[m].guessedCorrect.length > 0) {
          for (var n = array[m].guessedCorrect.length -1; n >= 0; n--) {
            //console.log( 'guessed correct ' + array[m].guessedCorrect[n][0]);
            //console.log('still on item ' + item);
            //increment on any previous in a row
            if (array[m].guessedCorrect[n][0] == (item - correctInARow)) {
              correctInARow++;
            }
          }
      }
      array[m].inARowCorrect = correctInARow;
      //console.log(correctInARow + ' correct in a row for ' + array[m].playerName);  
  };

    //check how many in a row incorrect the player has
    for (var o = 0; o < array.length; o++) {
      var wrongInARow = 0;
      if (array[o].guessedWrong.length > 0) {
          for (var p = array[o].guessedWrong.length -1; p >= 0; p--) {
            //console.log( 'guessed wrong ' + array[o].guessedWrong[p][0]);
            //console.log('still on item ' + item);
            //increment on any previous in a row
            if (array[o].guessedWrong[p][0] == (item - wrongInARow)) {
              wrongInARow++;
            }
          }
      }
      array[o].inARowWrong = wrongInARow;
      //console.log(correctInARow + ' correct in a row for ' + array[m].playerName);  
  };

    //check how many more the player needs to tie


    //check how many more the player needs to win



    setUndo([...players]);
    setPlayers(newPlayers);
    console.log(newPlayers);
  }

  const removePlayer = index => {
    const newPlayers = [...players];
    newPlayers.splice(index, 1);
    setPlayers(newPlayers);
    //console.log('Remove player ' + index);
  }

  const  handleUndo = lastClick => {
    //console.log('Undoing the last action.');
    const newPlayers = [...players];
    switch (lastClick[0]) {
      case 'correctAnswer': 
        //console.log('undo ' + lastClick[0]);
        newPlayers[lastClick[1]].guessedCorrect.pop(); // = parseInt(newPlayers[lastClick[1]].guessedCorrect , 10) - 1;
        newPlayers[lastClick[1]].winner = false;
        setPlayers(newPlayers);
        setItem(parseInt(item , 10) - 1);
        //console.log('Removed correct answer given by ' + newPlayers[lastClick[1]].playerName + ' on clue ' + clue + ' of item ' + item + ' of ' + initials);
        rankPlayers();
        break;
      
      case 'incorrectAnswer': 
        //console.log('undo ' + lastClick[0]);
        newPlayers[lastClick[1]].guessedItemWrong = false;
        newPlayers[lastClick[1]].guessedWrong.pop();// = parseInt(newPlayers[lastClick[1]].guessedWrong , 10) - 1;
        setPlayers(newPlayers);
        rankPlayers();
        //console.log('Incorrect answer given by ' + newPlayers[lastClick[1]].playerName + ' on clue ' + clue + ' of item ' + item + ' of ' + initials);
        break;
      
      case 'youBlewIt': 
        //console.log('undo ' + lastClick[0]);
        setItem(parseInt(item , 10) - 1);
        rankPlayers();
        //console.log('Undid the everyone blew it on the last question.');
        break;

      case 'nextClue': 
        //console.log('undo ' + lastClick[0]);
        setClue(parseInt(clue , 10) - 1);
        break;
      
      default:  
        //console.log('There is not task to undo.');
   }
    setLastClick([]);
  };


  const playerWrong = (player) => player.guessedItemWrong === true;

  const playerWins = (player) => player.winner === true;


  const addInitials = initials => {
    itsTimeToPlayInitialsAudio();
    setInitials(initials);
    //console.log('The initials selected for this game are ' + initials +'.');
    setGameState('PlayGame');
  };

  const playClue = (clue) => {
    if (parseInt(clue, 10) === 1) { cluesAudio({id : 'clue-1'})};
    if (parseInt(clue, 10) === 2) { cluesAudio({id : 'clue-2'})};
    if (parseInt(clue, 10) === 3) { cluesAudio({id : 'clue-3'})};
    if (parseInt(clue, 10) === 4) { cluesAudio({id : 'clue-4'})};
    if (parseInt(clue, 10) === 5) { cluesAudio({id : 'clue-5'})};
    if (parseInt(clue, 10) === 6) { cluesAudio({id : 'clue-6'})};
    //console.log('Clue ' + clue + ' announced.');
  };

  const nextClue = () => {
    (
    parseInt(clue, 10) < 6
      ? setClue(parseInt(clue , 10) + 1)
      : setClue(parseInt(clue , 10)));
    playClue(parseInt(clue , 10) + 1);
    setLastClick(['nextClue', parseInt(clue , 10)]);
  };

  const nextItem = () => {
    if (parseInt(item, 10) < 14) {
      setItem(parseInt(item , 10) + 1);
    } else {
      setItem(parseInt(item , 10));
    };
    setClue(0);
    resetGuesses();
  }

  const handleLoadingComplete = () => {
    setGameState('AddPlayers');
    //itsTimeToPlayInitialsAudio();
  };


  function GameBoard () {
    return (
      <div className='Gameboard'>
        {(((parseInt(clue, 10) < 6) && !players.every(playerWrong) && (parseInt(item, 10) <= 12)) || ((parseInt(clue, 10) < 6) && (parseInt(item, 10) > 12) && !players.some(playerWins))) &&
         <button
           className='btn-large waves-effect waves-light blue darken-4'
           onClick={nextClue}
           disabled={((parseInt(clue, 10) < 6) || (parseInt(item, 10) < 14 )) ? false : true}>
           Announce Clue #
           {parseInt(clue, 10) + 1} <i className='material-icons right'>volume_up</i>
         </button>}
        <div>
          <AllFailed />
        </div>
      </div>
    );
  };

  function AllFailed () {
    return (
      <div className='AllFaileded'>
        {((gameState == 'PlayGame' && parseInt(item, 10) < 14 && players.every(playerWrong)) || (gameState == 'PlayGame' && ( parseInt(clue, 10) > 5))) &&
          <button 
            className="btn-large waves-effect waves-light materialize-red" 
            type="button" onClick={() => { nextItem(); wrongAudio(); youBlewItAudio(); setLastClick(['youBlewIt',0]);
            //console.log('You blew it! Nobody answered item ' + item + ' Correctly.');
            }}>
              Nobody Answered Item {item} Correctly 
          </button>}
      </div>
    );
  };

  function CurrentClueAndItem () {
    let outputText = '';
    if (parseInt(item, 10) < 13 && (Number(parseInt(clue, 10)) === 0) && parseInt(item, 10) != 1) {
      outputText = 'Item ' + item + ' of the initials ' + initials + '. Time for a game recap before announcing the next clue.';
    } else if (parseInt(item, 10) === 1 && ((parseInt(clue, 10)) === 0)) {
      outputText = 'The Power Trip Morning Show plays The Initials Game every Friday and it works like this. I have 12 items, they can be people, places, things, phrases, whatever but they share the same initials. Each item will have 6 clues. I start reading clues, as soon as you know who or what I\'m describing, you yell out your name. Your name is your buzzer. You have to pronounce it correctly. If you get it right, you get a point. If you get it wrong, you are out for just that item. And away we go with the initials ' + initials + '!';
    } else if (parseInt(item, 10) < 13 && (Number(parseInt(clue, 10)) !== 0)) {
      outputText = 'Read clue number ' + clue + ' of item ' + item + ' of the initials ' + initials + '';
    } else if ((Number(parseInt(clue, 10) === 0)) && !players.some(playerWins)) {
      outputText = 'TIEBREAKER INITIALS item ' + (parseInt(item, 10) - 12) + ' of the initials ' + initials + '';
    } else if (!players.some(playerWins)) {
      outputText = 'Read clue number ' + clue + ' of TIEBREAKER INITIALS item ' + (parseInt(item, 10) - 12)  + ' of the initials ' + initials + '';
    } else {
      outputText = 'Thanks for using the Intitals Scoreboard to play The Initials Game. Refresh the page to start another round.';
    };
    return (
      <h4>{outputText}</h4>
    );
  };
  //console.log('app return');
  return (
      <div>
          {gameState == 'PlayGame' &&
          <div className='container'>
           <div className='game-board'>
             <CurrentClueAndItem />
             <GameBoard />
           </div>
           </div>}
          {initials == '' && gameState == 'AddInitials' &&
               <InitialsForm addInitials={addInitials} />
           }
          {gameState == 'AddPlayers' &&
          <div className='container'>
          <FadeIn>
             <h3>Give your players some names</h3>
             <PlayerForm
               addPlayer={addPlayer}
               setGameState={setGameState}
               players={players} />
           </FadeIn>
           </div>}
           <div className='container'>
            <div className='player-list'>
            {players.map((player, index) => (
               <FadeIn key={index}>
                 <Player
                   key={index}
                   index={index}
                   player={player}
                   incorrectAnswer={incorrectAnswer}
                   correctAnswer={correctAnswer}
                   removePlayer={removePlayer}
                   gameState={gameState}
                   item={item}
                   clue={clue} />
               </FadeIn>
             ))}
            {(gameState == 'PlayGame' && !(lastClick === undefined || lastClick.length === 0 || lastClick.length === [])) &&
              <div className="fixed-action-btn">
                <a className="btn-floating btn-large blue darken-2 tooltipped" data-position="top" data-tooltip="Undo the last answer" onClick={() => handleUndo(lastClick)}>
                  <i className="large material-icons">undo</i>
                </a>
                <ul>
                  <li><a className="btn-floating blue darken-2 tooltipped" data-position="top" data-tooltip="Undo the last answer" onClick={() => handleUndo(lastClick)}>Undo<i className="material-icons">undo</i></a></li>
                  <li><a className="btn-floating yellow darken-1"><i className="material-icons">format_quote</i></a></li>
                </ul>
              </div>
              }
             </div>
          </div>
          {gameState == 'Loading' &&
            <div>
            <div className="boxtop"></div>
              <div className="loading logo-header">
              <FadeIn>
              <img src={logo} className="App-logo scale-transition" alt="logo" />
              <h1>Scoreboard</h1>
              <p>It&apos;s time to play Initials, <i>not</i> on the Power Trip</p>
              <p>Unmute, crank the volume, and grab your <a href="https://www.initialsgame.com/">Initials Game cards</a>.</p>
              </FadeIn>
              <button className="btn-large waves-effect waves-light materialize-red" onClick={() => handleLoadingComplete()}>Away We Go!</button>
            </div>
            <div className="boxbottom"></div>
            </div>}
            {((gameState == 'PlayGame') || (gameState == 'Loading')) &&
                <footer className="page-footer font-small blue darken-4 text-center">
                 <div className="footer-copyright font-small">
                   <p className="text-muted"> 
                   <br/><a href="https://www.initialsscoreboard.com/">Initials Scoreboard</a> (<a href="https://twitter.com/InitialsScore">@InitialsScore</a>) is a rube created scoreboard and button bar to make it easy to keep score, add play by play commentary, and automatic button bar sounders as you use your official <a href="https://www.initialsgame.com/">The Initials Game cards</a>. Initials Scoreboard was created by Tim Conner of <a
                   href="https://objectivelabs.com/about.php"
                   target="_blank"
                   rel="noopener noreferrer"
                   >
                   Objective Labs
                   </a> (<a href="https://twitter.com/ObjectiveLabs">@ObjectiveLabs</a>). 
                    <br /> <br />
                   <a href="https://www.initialsgame.com/">The Initials Game</a> is a play at home card game that was created by Cory Cove of the KFAN Power Trip Morning show and is required to use this app. It is <a href="https://www.initialsgame.com/">available for purchase from the original creator himself</a> . This app will help make <i>The Initials Game</i> more fun and your contestants may even feel like they are part of the show.
                   <br /><br />
                   <a href="https://www.InitialsScoreboard.com/">InitialsScoreboard.com</a> has no affiliation with KFAN or Cory Cove.</p>
                 </div>
               </footer>
               }
      </div>
  );
};
export default App;
